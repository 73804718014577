<template>
  <div>
    <h4>Preguntas Frecuentes
      <!-- <img src="@/assets/images/wh.png"> -->
    </h4>
    <div class="faq_list">
      <div class="container">
        <!-- <h3 class="title">Frequently Asked Questions</h3> -->
        <!-- <img src="@/assets/images/loanImg/icon_faq.png" alt=""> -->
        <el-collapse v-model="activeNames" @change="handleChange">
          <el-collapse-item v-for="(item, index) in faqList" :id="item.id" :key="index" :title="item.title" :name="item.name">
            <div v-html="item.dec" />
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeNames: ['1'],
      faqList: [
        {
          name: '1',
          id: 'question-1',
          title: '1.Monto de préstamo',
          dec: 'DineroRápido ofrece préstamos hasta un máximo de 30,000 pesos, cuyo crédito exacto es evaluado por el sistema con base en la información de su solicitud. '
        },
        {
          name: '2',
          id: 'question-2',
          title: '2.¿Cómo solicitar un préstamo?',
          dec: 'Desde Google Playstore puede descargar DineroRápido, regístrese con su número celular e inicie sesión para solicitar un préstamo.'
        },
        {
          name: '3',
          id: 'question-3',
          title: '3.¿Cómo obtener el monto del préstamo?',
          dec: 'Inicie sesión en la aplicación. Después de ingresar la información personal solicitada de acuerdo al proceso, evaluaremos su solicitud después de completarla. Esto determinará si se le concederá una línea de crédito. Para asegurarse de poder obtener el monto del préstamo sin problemas, le recomendamos verificar la vigencia de toda su información.'
        },
        {
          name: '4',
          id: 'question-4',
          title: '4.Cometí un error al completar mi perfil, ¿puedo modificar mi perfil?',
          dec: 'Para la conservación y protección de datos personales, Después de la presentación exitosa de sus datos no se admite la modificación. Verifica cuidadosamente la información ingresada antes de enviar.'
        },
        {
          name: '5',
          id: 'question-5',
          title: '5.¿Cuánto tiempo dura el proceso de aprobación del préstamo?',
          dec: '1. Después de rellenar todos sus datos personales, la mayoría de las aprobaciones de los créditos de los préstamos se procesarán en 3 minutos. <br> 2. La mayoría de las aprobaciones del depósito del préstamo se completan en 1-2 horas, con un máximo de 24 horas. Si no ha recibido su préstamo después de 24 horas, póngase en contacto con servicio de atención al cliente.'
        },
        {
          name: '6',
          id: 'question-6',
          title: '6.¿Como recibir mi préstamo?',
          dec: 'Si su solicitud está aprobada, normalmente el depósito se tardará unas 2-3 horas en llegar con éxito a su cuenta. Las transferencias bancarias pueden retrasarse debido a problemas de la red del sistema, así que no se preocupe demasiado. Si el pago es exitoso, DineroRápido le enviará una notificación, así que sea paciente. DineroRápido solo empezará a calcular los intereses a partir del día siguiente a la realización del depósito. Si no ha recibido el depósito más de 24 horas, póngase en contacto con el servicio de atención al cliente.'
        },
        {
          name: '7',
          id: 'question-7',
          title: '7.¿Se puede incrementar el monto del préstamo?',
          dec: 'DineroRápido evalúa regularmente el crédito del usuario de forma razonable y le ofrece un límite de préstamo adecuado a su solvencia. Le recomendamos que mantenga un buen crédito de préstamo haciendo sus pagos a tiempo, ya que repetir un buen crédito le ayudará a obtener un crédito de préstamo más alto.'
        },
        {
          name: '8',
          id: 'question-8',
          title: '8.Si al tomar el préstamo este no fue aprobado, ¿puedo aplicar de nuevo?',
          dec: 'Si su solicitud de préstamo no es aprobada, no cumple con los requisitos para solicitar un préstamo de DineroRápido en este momento. Se le enviará la notificación si posteriormente cumple los requisitos de la solicitud y podrá volver a solicitarlo.'
        },
        {
          name: '9',
          id: 'question-9',
          title: '9.¿Puedo cancelar mi solicitud de préstamo?',
          dec: 'Una vez enviada la solicitud de préstamo, se procesará automáticamente y no se podrá cancelar.<br>Si cuenta con alguna duda, comuníquese con el servicio de atención al cliente.'
        },
        {
          name: '10',
          id: 'question-10',
          title:
            '10.¿Por qué no se ha aprobado mi solicitud de pedir un nuevo préstamo?',
          dec: 'DineroRápido evalúa cada préstamo de forma razonable, basándose en el historial de préstamos de cada usuario, su capacidad de reembolso y su crédito. Le recomendamos que mantenga un buen crédito devolviendo su préstamo a tiempo. Si su solicitud de préstamo no ha sido aprobada, es que aún no cumple con los requisitos para obtener un préstamo de DineroRápido.<br>Se le enviará la notificación si posteriormente cumple los requisitos de la solicitud y podrá volver a solicitarlo.'
        }
        // {
        //   name: '11',
        //   id: 'question-11',
        //   title: '11. ¿Puedo saber el contrato de préstamo de platasube por adelantado?',
        //   dec: 'Sí, haga clic aquí para ver <a href="/about_us?type=3">《Contrato de préstamos》</a>'
        // },
        // {
        //   name: '12',
        //   id: 'question-12',
        //   title: '12. ¿Cómo puedo aumentar mi crédito?',
        //   dec: 'La línea de crédito es generada automáticamente por el sistema, manteniendo un excelente y oportuno reembolso, lo que le permite obtener más dinero la próxima vez que pida prestado.'
        // }
      ]
    }
  },
  methods: {
    handleChange(val) {
      console.log(val)
    }
  }
}
</script>

<style lang="scss" scoped>
h4{
  width: 100%;
  padding:00px calc((100% - 1100px) / 2) 0;
  // background: linear-gradient(270deg, #C54347 0%, #7149BC 100%);
  background: #F16400;
  color: white;
  line-height: 120px;
  font-size: 25px;
  img{
    float: right;
    width: 100px;
    margin: 10px 120px 0 0;
  }
}
.faq_list {
  min-width: 1200px;
  padding: 50px calc((100% - 1100px) / 2) 50px;
  margin: 0 auto;
  img {
    display: block;
    margin: 0 auto;
    width: 350px;
    height: 300px;
  }
  .title {
    font-size: 30px;
    color: #333333;
    margin-bottom: 40px;
  }
}
:deep(.el-collapse-item__header) {
  background: #eeeeee;
  padding-left: 20px;
}
:deep(.el-collapse-item__content) {
  padding: 20px 480px 50px 20px;
}
@media only screen and (max-width: 665px) {
  h4{
    padding-left: 20px;
    font-size: 12px;
    line-height: 50px;
    img{
      width: 40px;
      margin: 5px 30px 0 0;
    }
  }
  .faq_list {
    min-width: calc(100vw);
    padding: 0 !important;
    .container {
      img {
        width: 250px;
        height: 200px;
        margin: 10px auto;
      }
    }
  }
  :deep(.el-collapse-item__header) {
    background: #eeeeee;
    padding-left: 20px;
    font-size: 12px !important;
    line-height: 24px !important;
    height: 50px !important;
  }
  :deep(.el-collapse-item__content) {
    padding: 20px 20px !important;
    font-size: 12px !important;
  }
}
</style>
